import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSelector } from 'react-redux';
import './styles.scss';

const OrderSummary = () => {

    const basket = useSelector(state => state.basket);
    const { contents, subtotal, deliveryCost, total } = basket
    const products = useSelector(state => state.products.products)

    return (
        <Container
            className="text-dark max-readable-width mt-4"
        >
            <Row>
                <Col>
                    <h3>Order Summary</h3>
                </Col>
            </Row>
            {
                (contents.length > 0) ?
                    contents.map((item, index) => {
                        return (
                            <Container
                                key={index}
                                className="basket-item-container"
                            >
                                <Row>
                                    <Col
                                        xs={6}
                                        sm={4}
                                        className="checkout-image-container p-0"
                                    >
                                        <img
                                            src={`${process.env.PUBLIC_URL}/img/${item.title.toLowerCase()}-product-photo.jpg`}
                                            className="checkout-image"
                                            alt={item.title + " soap bar"}
                                        />
                                    </Col>
                                    <Col
                                        xs={6}
                                        sm={8}
                                        className="d-flex flex-column justify-content-center"
                                    >
                                        <Row>
                                            <Col
                                            >
                                                <h5
                                                    className="my-2"
                                                >{item.title}</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                xs={8}
                                                sm={4}
                                            >
                                                <p
                                                    className="my-2"
                                                >QTY: {item.quantity}</p>
                                            </Col>
                                            <Col
                                                xs={4}
                                                sm={8}
                                            >
                                                {products &&
                                                    <p
                                                        className="my-2"
                                                    >£{products.find(obj => obj.id === item.id).price * item.quantity}</p>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        )
                    })
                    :
                    < Row >
                        <Col>
                            <p>Bag is currently empty</p>
                        </Col>
                    </Row>
            }
            <Row>
                <Col
                    xs={4}
                    sm={3}
                >
                    <p
                        className="my-1"
                    >Subtotal</p>
                </Col>
                <Col
                >
                    <p
                        className="my-1"
                    >£{subtotal.toFixed(2)}</p>
                </Col>
            </Row>
            <Row>
                <Col
                    xs={4}
                    sm={3}
                >
                    <p
                        className="my-1"
                    >Delivery</p>
                </Col>
                <Col>
                    <p
                        className="my-1"
                    >{
                            deliveryCost > 0 ?
                                `£${deliveryCost.toFixed(2)}`
                                :
                                `Free`
                        }
                    </p>
                </Col>
            </Row>
            <Row>
                <Col
                    xs={4}
                    sm={3}
                >
                    <h5
                        className="my-1"
                    >Total</h5>
                </Col>
                <Col>
                    <h5
                        className="my-1"
                    >£{total.toFixed(2)}</h5>
                </Col>
            </Row>
        </Container >
    )
}

export default OrderSummary
