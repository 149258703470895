import { createAsyncThunk, createSlice } from '@reduxjs/toolkit/';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase';

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (arg, { dispatch }) => {
    const {
      uid,
      email,
      emailVerified,
      displayName,
      isAnonymous
    } = arg;
    if (uid) {
      dispatch(getStripeCustomerInfo(uid))
      return ({
        uid: uid,
        email: email,
        emailVerified: emailVerified,
        displayName: displayName,
        isAnonymous: isAnonymous
      })

    }
    else {
      return ({
        uid: null,
        email: null,
        emailVerified: null,
        displayName: null,
        isAnonymous: null,
        stripe: null
      })
    }
  }
)

export const getStripeCustomerInfo = createAsyncThunk(
  'user/getStripeCustomerInfo',
  async (uid, { rejectWithValue }) => {
    try {
      const docSnap = await getDoc(doc(db, "stripe_customers", uid));
      if (docSnap.exists()) {
        return docSnap.data();
      }
      return rejectWithValue(`No Stripe customer found in Firestore for user: ${uid}`)
    }
    catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const authSlice = createSlice({
  name: "user",
  initialState: {
    uid: null,
    email: null,
    emailVerified: null,
    displayName: null,
    isAnonymous: null,
    loading: true,
    error: null,
    stripe: {
      customer_id: null,
      setup_secret: null,
      loading: false,
      error: null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const {
          uid,
          email,
          emailVerified,
          displayName,
          isAnonymous
        } = action.payload;
        state.uid = uid
        state.email = email
        state.emailVerified = emailVerified
        state.displayName = displayName
        state.isAnonymous = isAnonymous
        state.loading = false
        state.error = null
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getStripeCustomerInfo.pending, (state, action) => {
        state.stripe.loading = true;
        state.stripe.error = null;
      })
      .addCase(getStripeCustomerInfo.fulfilled, (state, action) => {
        state.stripe.loading = false;
        state.stripe.customer_id = action.payload.customer_id;
        state.stripe.setup_secret = action.payload.setup_secret;
      })
      .addCase(getStripeCustomerInfo.rejected, (state, action) => {
        state.stripe.loading = false;
        state.stripe.error = action.payload;
      })
  }
});

export default authSlice.reducer;