import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase'

const baseUrl = process.env.REACT_APP_BASE_URL

export const handleRegister = async (email, password) => {
    try {
        await createUserWithEmailAndPassword(auth, email, password);
        return "Success"
    } catch (error) {
        throw error
    }
}

export const handleSignIn = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        return "Success";
    } catch (error) {
        throw error;
    }
}

export const handleSignOut = async () => {
    try {
        await signOut(auth);
        window.location.reload(false);
    } catch (error) {
        console.log("There was a problem signing out");
    }
}

export const resetPassword = async (email, destination) => {
    try {
        await sendPasswordResetEmail(auth, email, {url: `${baseUrl}${destination}`})
        return
    }
    catch (error) {
        throw error
    }
}

export const authStateObserver = (dispatch, action) => {
    return onAuthStateChanged(auth, (user) => {
        if (user) {
            const { uid, email, emailVerified, displayName, isAnonymous } = user;
            dispatch(action({
                uid: uid,
                email: email,
                emailVerified: emailVerified,
                displayName: displayName,
                isAnonymous: isAnonymous
            }))
        } else {
            dispatch(action({
                uid: null,
                email: null,
                emailVerified: null,
                displayName: null,
                isAnonymous: null
            }))
        }
    })
}