import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { confirmShippingAddress, editShippingAddress, updateShippingAddress } from '../../features/stripe/stripeSlice';

const ShippingForm = () => {

    const dispatch = useDispatch();
    const shippingDetails = useSelector(state => state.stripe.shippingAddress)

    const handleChange = (e) => {
        dispatch(updateShippingAddress({
            ...shippingDetails,
            [e.target.id]: e.target.value
        }))
    }

    const detailsButton = () => {
        if (!shippingDetails.isConfirmed) {
            dispatch(confirmShippingAddress(
                shippingDetails
            ))
        }
        else {
            dispatch(editShippingAddress())
        }
    }

    return (
        <Container
            className="max-readable-width mt-4"
        >
            <h4>Shipping Address</h4>
            <Form>
                <Row>
                    <Col>
                        <Form.Group
                            className="mb-3 address-form-input"
                            controlId="fname"
                        >
                            <Form.Label>First Name (required)</Form.Label>
                            <Form.Control
                                type="text"
                                value={shippingDetails.fname}
                                onChange={(e) => handleChange(e)}
                                disabled={shippingDetails.isConfirmed}
                                className={shippingDetails.isConfirmed && "override-autofill-color"}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group
                            className="mb-3 address-form-input"
                            controlId="lname"
                        >
                            <Form.Label>Last Name (required)</Form.Label>
                            <Form.Control
                                type="text"
                                value={shippingDetails.lname}
                                onChange={(e) => handleChange(e)}
                                disabled={shippingDetails.isConfirmed}
                                className={shippingDetails.isConfirmed && "override-autofill-color"}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group
                    className="mb-3 address-form-input"
                    controlId="addressLine1"
                >
                    <Form.Label>Address Line 1 (required)</Form.Label>
                    <Form.Control
                        type="address-line1"
                        value={shippingDetails.addressLine1}
                        onChange={(e) => handleChange(e)}
                        disabled={shippingDetails.isConfirmed}
                        className={shippingDetails.isConfirmed && "override-autofill-color"}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3 address-form-input"
                    controlId="addressLevel1"
                >
                    <Form.Label>Address Line 2</Form.Label>
                    <Form.Control
                        type="address-level1"
                        value={shippingDetails.addressLevel1}
                        onChange={(e) => handleChange(e)}
                        disabled={shippingDetails.isConfirmed}
                        className={shippingDetails.isConfirmed && "override-autofill-color"}
                    />
                </Form.Group>
                <Row>
                    <Col>
                        <Form.Group
                            className="mb-3"
                            controlId="addressLevel2"
                        >
                            <Form.Label>Town / City (required)</Form.Label>
                            <Form.Control
                                type="address-level2"
                                value={shippingDetails.addressLevel2}
                                onChange={(e) => handleChange(e)}
                                disabled={shippingDetails.isConfirmed}
                                className={shippingDetails.isConfirmed && "override-autofill-color"}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group
                            className="mb-3"
                            controlId="postalCode"
                        >
                            <Form.Label>Postcode (required)</Form.Label>
                            <Form.Control
                                type="postal-code"
                                value={shippingDetails.postalCode}
                                onChange={(e) => handleChange(e)}
                                disabled={shippingDetails.isConfirmed}
                                className={shippingDetails.isConfirmed && "override-autofill-color"}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            onClick={detailsButton}
                            variant={shippingDetails.isConfirmed ? "outline-secondary" : "secondary"}
                            disabled={!shippingDetails.isValid}>
                            {shippingDetails.isConfirmed ? "Edit details" : "Confirm details"}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

export default ShippingForm
