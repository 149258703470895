import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ comp: Component, ...rest }) => {

    var uri = encodeURI("/")
    if (rest.path) {
        uri = encodeURI(rest.path)
    }

    const user = useSelector((state) => state.user)

    if (user.loading) {
        return (
            null
        )
    }

    if (user.uid) {
        return (
            <Route
                {...rest}
                render={routeProps => (
                    <Component {...routeProps} />
                )}
            />
        )
    }

    return (
        <Redirect to={`/sign-in?destination=${uri}`} />
    )

}

export default ProtectedRoute
