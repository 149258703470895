
const HashScroll = ({ id }) => {
    return (
        <div
            id={id}
            className="hash-offset"
        />
    )
}

export default HashScroll
