import './styles.scss';
import { NavHashLink as Link } from 'react-router-hash-link';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Footer = () => {
    return (
        <Container
            className="footer bg-light d-flex flex-column justify-content-center"
            fluid
        >
            <Row
                className="footer-container"
            >
                <Col
                    className="footer-details"
                    xs={12}
                    md={9}
                >
                    <p>©LUSTRUS LTD | <a href="https://evt.digital">Website powered by EVT Digital</a></p>
                </Col>
                <Col
                    className="footer-details"
                    xs={12}
                    md={3}
                >
                    <p>
                        <Link
                            to="/privacy-policy"
                        >Privacy Policy</Link>
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer
