import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const toastSlice = createSlice({
    name: "toast",
    initialState: [],
    reducers: {
        queueToast: (state, action) => {
            const newState = state;
            const { type, message } = action.payload;
            toast(message, { type: type });
            newState.push({ type: type, message: message });
        }
    }
})

export const { queueToast } = toastSlice.actions;

export default toastSlice.reducer;