import { useState } from 'react';
import './styles.scss';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { NavHashLink as Link } from 'react-router-hash-link';
import { useDispatch } from 'react-redux';
import { getCookies, setCookies } from '../../features/cookies/cookiesSlice';

const CookiePopup = () => {

    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false)
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const [analyticsToggle, setAnalyticsToggle] = useState(false);

    const saveCookies = () => {
        if (analyticsToggle === true) {
            console.log("Runs?")
            dispatch(setCookies({
                name: 'cookiesPolicy',
                value: 'all',
                options: { expires: 90, path: '' }
            }))
        }
        else {
            console.log("Runs?")
            dispatch(setCookies({
                name: 'cookiesPolicy',
                value: 'essential',
                options: { expires: 90, path: '' }
            }))
        }
        dispatch(getCookies())
    }

    const acceptAllCookies = () => {
        setAnalyticsToggle(true)
        dispatch(setCookies({
            name: 'cookiesPolicy',
            value: 'all',
            options: { expires: 90, path: '' }
        }))
        dispatch(getCookies())
    }

    const toggleAnalytics = () => {
        setAnalyticsToggle(!analyticsToggle)
    }

    return (
        <>
            <div
                className="cookie-jar bg-light text-dark"
            >
                <p>We use necessary cookies to make our site work. We'd also like to set analytics cookies that help us make improvements by measuring how you use the site. These will be set only if you accept.</p>
                <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleShow}
                >
                    Manage Cookies
                </Button>
                <Button
                    className="accept-button"
                    variant="secondary"
                    size="sm"
                    onClick={acceptAllCookies}
                >
                    Accept All
                </Button>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Our use of cookies</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>We use necessary cookies to make our site work. We'd also like to set analytics cookies that help us make improvements by measuring how you use the site. These will be set only if you accept.</p>
                    <p>For more detailed information about the cookies we use, see our <Link to="/privacy-policy/#cookies">Cookies page</Link>.</p>
                    <h4>
                        <Form>
                            <Form.Check
                                disabled
                                checked
                                type="switch"
                                label="Necessary cookies"
                                id="disabled-custom-switch"
                            />
                        </Form>
                    </h4>
                    <p>Necessary cookies enable core functionality such as security, network management, and accessibility. You may disable these by changing your browser settings, but this may affect how the website functions.</p>
                    <h4>
                        <Form>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Analytics cookies"
                                checked={analyticsToggle}
                                onChange={toggleAnalytics}
                            />
                        </Form>
                    </h4>
                    <p>We'd like to set Google Analytics cookies to help us improve our website by collecting and reporting information on how you use it. The cookies collect information in a way that does not directly identify anyone.</p>
                    <p>For more information on how these cookies work please see our 'Cookies page'.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={saveCookies}>
                        Save Changes
                    </Button>
                    <Button variant="secondary" onClick={acceptAllCookies}>
                        Accept All
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CookiePopup;