import { useState, useEffect } from 'react';
import './styles.scss';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { handleRegister } from '../../utils/auth';
import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from '../../utils/react-router-hooks';

const Register = () => {

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [userDetails, setUserDetails] = useState({
        email: "",
        password: ""
    });
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState({
        message: null,
        code: null,
    });

    useEffect(() => {
        const { email, password } = userDetails;
        if (!!email && !!password) {
            setButtonDisabled(false)
        }
        else {
            setButtonDisabled(true)
        }
    }, [userDetails])

    const handleSubmit = (e) => {
        e.preventDefault();
        setError({
            message: null,
            code: null,
        })
        handleRegister(userDetails.email, userDetails.password)
            .then()
            .catch((error) => {
                setError(error)
                if (error.code === "auth/email-already-in-use") {
                    setError({
                        message: "There is already an account with this email address",
                        code: error.code,
                    });
                }
                else {
                    setError({
                        message: "There was a problem registering, please try again later.",
                        code: error.code,
                    });
                }
            })
    }

    const userState = useSelector((state) => state.user);

    const query = useQuery()
    let destination
    destination = query.get("destination") || "/"
    const locationURI = encodeURIComponent(destination)

    if (userState.uid) {
        return (
            <Redirect to={destination ? destination : "/"} />
        )
    }

    return (
        <Container
            className="register d-flex justify-content-center align-items-center"
            fluid
        >
            <Row
                className="justify-content-center mt-4 sign-up-form-container"
            >
                <Col>
                    <h3>Create an account</h3>
                    <Form
                        onSubmit={handleSubmit}
                    >
                        <Form.Group
                            className="mb-3 form-field-email"
                            controlId="email"
                            value={userDetails.email}
                            onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
                        >
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="password"
                            value={userDetails.password}
                            onChange={(e) => setUserDetails({ ...userDetails, password: e.target.value })}
                        >
                            <Form.Label>Password</Form.Label>
                            <InputGroup>
                                <Form.Control type={showPassword ? "text" : "password"} autoComplete="new-password" />
                                <InputGroup.Text
                                    onClick={() => { setShowPassword(!showPassword) }}
                                >
                                    <i
                                        className={showPassword ? "bi bi-eye" : "bi bi-eye-slash"}
                                    />
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        <p
                        className="text-danger sign-up-error-message"
                        >{error.message}</p>
                        <Button
                            variant="secondary"
                            disabled={buttonDisabled}
                            type="submit"
                        >
                            Sign Up
                        </Button>
                    </Form>
                    <p
                        className="mt-3"
                    >
                        Already have an account? <Link to={`/sign-in?destination=${locationURI}`}>Sign in</Link>
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default Register
