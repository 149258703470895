import { initializeApp, getApp } from "firebase/app"
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { getAuth } from 'firebase/auth';

const firebase = initializeApp({
    apiKey: "AIzaSyD2zaFKyHhXq7G2XH5lf9OCPsAv8UJIMZQ",
    authDomain: "lustrus-website.firebaseapp.com",
    projectId: "lustrus-website",
    storageBucket: "lustrus-website.appspot.com",
    messagingSenderId: "725704718765",
    appId: "1:725704718765:web:525635403d8bf63a7a4924",
    measurementId: "G-69BW24RBVM"
});

export const db = getFirestore();

export const auth = getAuth();

export const functions = getFunctions();

if (process.env.REACT_APP_FIREBASE_EMULATOR_FUNCTIONS === "true"){
    console.log("Using Functions emulator")    
    connectFunctionsEmulator(functions, "localhost", 5001);
}
if (process.env.REACT_APP_FIREBASE_EMULATOR_FIRESTORE === "true"){
    console.log("Using Firestore emulator")    
    connectFirestoreEmulator(db, "localhost", 8080);
}

export const newsletterFunction = httpsCallable(functions, 'addSubscriber');
