import { createAsyncThunk, createSlice } from '@reduxjs/toolkit/';
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from '../../firebase';

export const getPaymentDocs = createAsyncThunk(
    'payments/getPaymentDocs',
    async (arg, { getState, rejectWithValue }) => {
        const state = getState();
        const { user } = state;
        try {
            const q = await getDocs(query(collection(db, 'stripe_customers', user.uid, 'payments'), where('status', '==', 'succeeded')));
            const payments = [];
            q.forEach(doc => {
                payments.push(doc.data());
            });
            return payments;
        }
        catch (e) {
            const { code, name, message, stack } = e
            return rejectWithValue({
                code: code,
                name: name,
                message: message,
                stack: stack
            });
        }
    }
)

const paymentsSlice = createSlice({
    name: 'payments',
    initialState: {
        payments: [],
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPaymentDocs.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getPaymentDocs.fulfilled, (state, action) => {
                action.payload.forEach((data) => {
                    if (state.payments.findIndex(s => s.paymentIntent.id === data.paymentIntent.id) === -1) {
                    state.payments.push(data)
                    }
                })
                state.loading = false;
            })
            .addCase(getPaymentDocs.rejected, (state, action) => {
                state.loading = false;
                const { code, name, message, stack } = action.payload;
                state.error = {
                    code: code,
                    name: name,
                    message: message,
                    stack: stack
                };
            })
    }
})

export default paymentsSlice.reducer;