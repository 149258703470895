import './styles.scss';
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { updateBasket, hideBasket } from "../../features/basket/basketSlice";

const Basket = () => {

    const basket = useSelector(state => state.basket);
    const basketContents = basket.contents;
    const basketVisibility = basket.visibility;
    const products = useSelector(state => state.products.products)

    const dispatch = useDispatch();

    const dispatchUpdateBasket = (id, title, quantity) => {
        dispatch(updateBasket({
            id: id,
            title: title,
            quantity: quantity
        }))
    }

    const dispatchHideBasket = () => {
        dispatch(hideBasket());
    }

    const hiddenClass = (basketVisibility === true) ? "" : "basket-container-hidden";

    const history = useHistory();

    const goToCheckout = () => {
        history.push("/checkout")
        dispatchHideBasket()
    } 

    return (
        <Container
            fluid
            className={`basket-container ${hiddenClass}`}
        >
            {
                basketVisibility === true &&
                <Container
                    className="basket"
                >
                    <Container
                        className="basket-contents"
                    >
                        <Row>
                            <Col
                                className="basket-top"
                            >
                                <h3>Bag
                                </h3>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={() => dispatchHideBasket()}
                                >
                                    <i className="bi bi-x-lg" style={{ fontSize: "1.25rem" }}></i>
                                </Button>
                            </Col>
                        </Row>
                        <Dropdown.Divider />
                        {
                            (basketContents.length > 0) ?
                            basketContents.map((item, index) => {
                                    return (
                                        <Container
                                            key={index}
                                            className="basket-item-container"
                                        >
                                            <Row>
                                                <Col
                                                    xs={4}
                                                    className="basket-image-container d-flex flex-column"
                                                >
                                                    <img
                                                    src={`${process.env.PUBLIC_URL}/img/${item.title.toLowerCase()}-product-photo.jpg`}
                                                    alt={item.title + " soap bar"}
                                                    />
                                                </Col>
                                                <Col
                                                    xs={8}
                                                >
                                                    <Row>
                                                        <Col
                                                            xs={4}
                                                        >
                                                            <p>{item.title}</p>
                                                        </Col>
                                                        <Col
                                                            xs={6}
                                                        >
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    xs={4}
                                                    className="quantity-select-container"
                                                >
                                                    <Form.Control
                                                        aria-label="Quantity"
                                                        as="select"
                                                        className="form-select"
                                                        value={item.quantity}
                                                        size="sm"
                                                        onChange={(e) => dispatchUpdateBasket(item.id, item.title, parseInt(e.target.value, 10))}
                                                    >
                                                        <option value="0">QTY: 0</option>
                                                        <option value="1">QTY: 1</option>
                                                        <option value="2">QTY: 2</option>
                                                        <option value="3">QTY: 3</option>
                                                        <option value="4">QTY: 4</option>
                                                        <option value="5">QTY: 5</option>
                                                        <option value="6">QTY: 6</option>
                                                        <option value="7">QTY: 7</option>
                                                        <option value="8">QTY: 8</option>
                                                        <option value="9">QTY: 9</option>
                                                        <option value="10">QTY: 10</option>
                                                    </Form.Control>
                                                </Col>
                                                <Col
                                                    xs={8}
                                                >
                                                    {products &&
                                                        < p >£{products.find(obj => obj.id === item.id).price * item.quantity}</p>
                                                    }
                                                </Col>
                                            </Row>
                                        </Container>
                                    )
                                })
                                :
                                < Row >
                                    <Col>
                                        <p>Bag is currently empty</p>
                                    </Col>
                                </Row>
                        }
                    </Container>
                    <Container
                        className="basket-total mb-2"
                    >
                        <h4>
                           Subtotal
                        </h4>
                        <h4>
                            £{basket.subtotal}
                        </h4>
                    </Container>
                    <Button
                        variant="secondary"
                        size="lg"
                        className="checkout-button"
                        disabled={basketContents.length === 0}
                        onClick={goToCheckout}
                    >
                        Checkout
                    </Button>
                </Container>
            }
        </Container >
    )
}

export default Basket
