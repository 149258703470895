import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const updateTotals = createAsyncThunk(
    'basket/updateTotals',
    (arg, { getState }) => {
        const { products } = getState();
        const productsArray = products.products
        const subtotal = (arg.reduce((previousValue, currentValue) =>
            previousValue + (productsArray.find(obj => obj.id === currentValue.id).price * currentValue.quantity), 0))
        const deliveryCost = subtotal > 30 ? 0 : 3.5;
        const basketTotal = subtotal + deliveryCost;

        return ({
            subtotal: subtotal,
            deliveryCost: deliveryCost,
            total: basketTotal,
        })
    }
)

export const addToBasket = createAsyncThunk(
    'basket/addToBasket',
    (arg, { getState, dispatch }) => {
        const { basket } = getState();
        const contents = JSON.parse(JSON.stringify(basket.contents));
        const { id, title, quantity } = arg;
        const arrayId = contents.findIndex((obj) => obj.id === id)
        if (arrayId !== -1) {
            if (contents[arrayId].quantity >= 10) {
                contents[arrayId].quantity = 10
            }
            else {
                contents[arrayId].quantity = contents[arrayId].quantity + quantity
            }
        }
        else {
            const newItemInBasket = {
                id: id,
                title: title,
                quantity: quantity
            }
            contents.push(newItemInBasket)
        }
        dispatch(updateTotals(contents))
        return contents

    }
);

export const updateBasket = createAsyncThunk(
    'basket/updateBasket',
    (arg, { getState, dispatch }) => {
        const { basket } = getState();
        const contents = JSON.parse(JSON.stringify(basket.contents));
        const { id, quantity } = arg;
        const arrayId = contents.findIndex((obj) => obj.id === id)
        if (arrayId !== -1) {
            if (quantity === 0) {
                contents.splice(arrayId, 1)
            }
            else {
                contents[arrayId].quantity = quantity
            }
        }
        dispatch(updateTotals(contents))
        return contents;
    }
)

const basketSlice = createSlice({
    name: "basket",
    initialState: {
        contents: [],
        visibility: false,
        subtotal: 0,
        deliveryCost: 0,
        total: 0,
        loading: false,
        error: null
    },
    reducers: {
        showBasket: (state) => {
            document.body.classList.add("basket-visible");
            state.visibility = true;
        },
        hideBasket: (state) => {
            document.body.classList.remove("basket-visible");
            state.visibility = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addToBasket.pending, (state, action) => {
                state.loading = true
            })
            .addCase(addToBasket.fulfilled, (state, action) => {
                state.loading = false;
                const contents = action.payload;
                state.contents = contents;
            })
            .addCase(addToBasket.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(updateBasket.pending, (state, action) => {
                state.loading = true
            })
            .addCase(updateBasket.fulfilled, (state, action) => {
                state.loading = false;
                const contents = action.payload;
                state.contents = contents;
            })
            .addCase(updateBasket.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(updateTotals.pending, (state, action) => {
                state.loading = true
            })
            .addCase(updateTotals.fulfilled, (state, action) => {
                state.loading = false;
                const { subtotal, deliveryCost, total } = action.payload;
                state.subtotal = subtotal;
                state.deliveryCost = deliveryCost;
                state.total = total;
            })
            .addCase(updateTotals.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
    }
})

export const { showBasket, hideBasket } = basketSlice.actions;

export default basketSlice.reducer;