import { useState, useEffect } from 'react';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { createPaymentIntent, updatePaymentIntent } from '../../features/stripe/stripeSlice';
import OrderSummary from '../../components/OrderSummary';
import ShippingForm from '../../components/ShippingForm';
import { Redirect } from 'react-router';
import { getStripeCustomerInfo } from '../../features/user/userSlice';

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
const baseUrl = process.env.REACT_APP_BASE_URL

const stripePromise = loadStripe(publishableKey)

const appearance = {
    variables: {
        colorPrimary: "#32325d",
        fontFamily: '"Montserrat", sans-serif',
        fontSizeBase: "1rem",
    }
}

const CheckoutForm = () => {
    const stripeHooks = useStripe();
    const elements = useElements();
    const [checkoutError, setCheckoutError] = useState();

    const stripe = useSelector((state) => state.stripe);
    const shippingAddress = stripe.shippingAddress
    const addressValid = shippingAddress.isValid;
    const [cardValid, setCardValid] = useState(false);

    const [loading, setLoading] = useState(true);
    const [pendingPayment, setPendingPayment] = useState(false);

    const handlePaymentElementChange = (e) => {
        if (!!e.complete) {
            setCardValid(true)
        }
        else {
            setCardValid(false)
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setCheckoutError(null);
        setPendingPayment(true);
        const { fname, lname, addressLine1, addressLevel1, addressLevel2, postalCode } = shippingAddress;
        const { error } = await stripeHooks.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${baseUrl}/order-confirmation/success/`,
                shipping: {
                    address: {
                        line1: addressLine1,
                        line2: addressLevel1,
                        city: addressLevel2,
                        postal_code: postalCode,
                    },
                    name: `${fname} ${lname}`,
                },
            },
        });
        setPendingPayment(false);
        if (error.type === "card_error" || error.type === "validation_error") {
            setCheckoutError(error.message);
        } else {
            setCheckoutError("An unexpected error occured. Please try again.");
            console.log(error)
        }


    }

    return (
        <Container className="max-readable-width">

            <h4>Billing Details</h4>
            {
                loading &&
                <p>Loading...</p>
            }
            <form
                onSubmit={handleSubmit}
            >
                <PaymentElement options={{
                    fields: {
                        billingDetails: 'auto',
                    },
                }}
                    onChange={handlePaymentElementChange}
                    onReady={() => setLoading(false)}
                />
                <p style={{ fontSize: "0.8rem", height: "10px", color: "#fa755a", }}>{checkoutError}</p>
                <Row>
                    <Col>
                        <Button
                            type="submit"
                            variant="secondary"
                            disabled={!stripeHooks || !elements || !addressValid || !cardValid || !!pendingPayment}>
                            Pay
                        </Button>
                    </Col>
                </Row>
            </form>
        </Container >
    );
};

const Checkout = () => {

    const { user, basket, stripe } = useSelector((state) => state);
    const { clientSecret, paymentIntentId } = stripe
    const dispatch = useDispatch();

    useEffect(() => {
        if (!!basket && !!user.stripe.customer_id && stripe.clientSecret === null) {
            dispatch(createPaymentIntent({
                basket: basket,
                customer_id: user.stripe.customer_id,
                fname: stripe.shippingAddress.fname,
                lname: stripe.shippingAddress.lname,
            }))
        }
        else if (!!basket && !!user.stripe.customer_id && !!stripe.clientSecret) {
            dispatch(updatePaymentIntent({
                basket: basket,
                id: stripe.paymentIntentId,
            }))
        }
        else if (user.uid && !user.loading && !user.stripe.loading) {
            dispatch(getStripeCustomerInfo(user.uid))
        }

    }, [dispatch, user])

    if (!!basket.loading || !!user.loading) {
        return (
            <Container>
                <Row>
                    <Col>
                        <p>Loading...</p>
                    </Col>
                </Row>
            </Container>
        )
    }

    if (!basket.loading && basket.contents.length === 0) {
        return (
            <Redirect
                to="/"
            />
        )
    }

    return (
        <Container
            className="mb-3"
        >
            <OrderSummary />
            <Container
                className="max-readable-width"
            >
                <hr />
            </Container>
            <ShippingForm />
            <Container
                className="max-readable-width"
            >
                <hr />
            </Container>
            {!stripe.loading ?
                (
                    stripe.shippingAddress.isConfirmed ?
                        (
                            !!stripePromise && !!clientSecret & !!paymentIntentId ?
                                <Elements
                                    options={{
                                        clientSecret,
                                        appearance,
                                    }}
                                    stripe={stripePromise}
                                >
                                    <CheckoutForm

                                    />
                                </Elements>
                                :
                                <Container
                                    className="max-readable-width"
                                >
                                    <Row>
                                        <Col>
                                            <p>There was a problem loading our payment system. Please email <a href="mailto:hello@lustrus.co">hello@lustrus.co</a> if the problem persists.</p>
                                        </Col>
                                    </Row>
                                </Container>
                        )
                        :
                        <Container
                            className="max-readable-width"
                        >
                            <Row>
                                <Col>
                                    <p>Confirm shipping details to proceed</p>
                                </Col>
                            </Row>
                        </Container>
                )
                :
                <Container
                    className="max-readable-width"
                >
                    <Row>
                        <Col>
                            <p>Loading...</p>
                        </Col>
                    </Row>
                </Container>
            }
        </Container>
    )
}


export default Checkout;