import "./index.scss";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import { NavHashLink } from 'react-router-hash-link';
import { useDispatch, useSelector } from 'react-redux';
import { showBasket } from "../../features/basket/basketSlice";
import { handleSignOut } from '../../utils/auth';
import { useLocation } from "react-router";

const NavBar = ({ routes }) => {

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user)
    const basket = useSelector((state) => state.basket)
    const basketCounter = basket.contents.length

    const dispatchShowBasket = () => {
        dispatch(showBasket());
    }

    const location = useLocation()
    const locationURI = encodeURIComponent(location.pathname + location.hash)

    const routeIndex = routes.findIndex((route) => route.path === location.pathname)
    var currentRoute = {
        hideNav: false
    }
    if (routeIndex !== -1) {
        currentRoute = routes[routeIndex]
    }

    return (
        <Navbar expand="sm" className="justify-content-end navbar-wrapper sticky-top">
            <Container className="navbar-container">
                <Navbar.Brand
                    className="text-dark me-auto"
                    as={NavHashLink}
                    to="/#">
                    LUSTRUS
                </Navbar.Brand>
                {!currentRoute.hideNav &&
                    <>
                        <Nav className="me-2 d-none d-sm-flex">
                            <Nav.Link
                                as={NavHashLink}
                                to="/#shop"
                            >
                                Shop
                            </Nav.Link>
                            <Nav.Link
                                as={NavHashLink}
                                to="/our-story"
                            >
                                Our Story
                            </Nav.Link>
                            <Nav.Link
                                as={NavHashLink}
                                to="/#contact"
                            >
                                Get in Touch
                            </Nav.Link>
                        </Nav>
                        {
                            user.uid ?
                                <Dropdown
                                    align="end"
                                >
                                    <Dropdown.Toggle
                                        variant="outline-secondary"
                                        id="dropdown-basic"
                                        className="d-none d-sm-block"
                                    >
                                        <i className="bi bi-person" style={{ fontSize: "1.25rem" }}></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => handleSignOut()}
                                        >
                                            Logout
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                :
                                <Button
                                    align="end"
                                    variant="outline-secondary"
                                    as={NavHashLink}
                                    to={`/sign-in?destination=${locationURI}`}
                                >
                                    <i className="bi bi-person" style={{ fontSize: "1.25rem" }}></i>
                                </Button>
                        }
                        <Button
                            onClick={() => dispatchShowBasket()}
                            align="end"
                            className="ms-2"
                            variant="outline-secondary"
                        >
                            <i
                                className="bi bi-bag"
                                style={{
                                    fontSize: "1.25rem",
                                    position: "relative",
                                }}
                            >
                                {basketCounter > 0 &&
                                    <span
                                        className="basket-number"
                                    >
                                        {basketCounter}
                                    </span>
                                }
                            </i>
                        </Button>
                        <Navbar.Toggle
                            as={Button}
                            aria-controls="responsive-navbar-nav custom-toggler"
                            type="button"
                            bsPrefix=" "
                            variant="outline-secondary"
                            className="ms-2  d-block d-sm-none"
                        >
                            <i className="bi bi-list" style={{ fontSize: "1.25rem" }}></i>
                        </Navbar.Toggle>
                        <Navbar.Collapse
                            id="responsive-navbar-nav"
                            className="nav-mobile-only"
                        >
                            <Nav className="me-auto d-block d-sm-none">
                                <Nav.Link
                                    as={NavHashLink}
                                    to="/#shop"
                                >
                                    Shop
                                </Nav.Link>
                                <Nav.Link
                                    as={NavHashLink}
                                    to="/our-story"
                                >
                                    Our Story
                                </Nav.Link>
                                <Nav.Link
                                    as={NavHashLink}
                                    to="/#contact"
                                >
                                    Get in Touch
                                </Nav.Link>
                                {
                                    user.uid ?
                                        <Nav.Link
                                            onClick={() => handleSignOut()}
                                        >
                                            Logout
                                        </Nav.Link>
                                        :
                                        <Nav.Link
                                            as={NavHashLink}
                                            to={`/sign-in?destination=${locationURI}`}
                                        >
                                            Sign In
                                        </Nav.Link>

                                }
                            </Nav>
                        </Navbar.Collapse>
                    </>
                }
            </Container>
        </Navbar >
    )
}

export default NavBar
