import "./styles.scss";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Newsletter from "../../components/Newsletter";
import productBanner from '../../assets/product-banner.jpg';
import AllProducts from "../../components/AllProducts";
import HashScroll from '../../components/HashScroll';

const Home = () => {

    return (
        <>
            <Container fluid>
                <Row
                    className="darken-image"
                >
                    <Col
                        className="hero-image"
                        style={{
                            backgroundImage: `linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)), url(${productBanner})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundBlendMode: "normal",
                        }}

                    >
                        <h1
                            className="h1-hero d-none d-sm-block"
                        >Minimalist artisanal skincare
                        </h1>
                        <h1
                            className="h1-hero d-flex d-sm-none"
                        >Minimalist<br />artisanal<br />skincare
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <h2
                        className="h2-hero bg-primary text-light"
                    >luxury · handcrafted · vegan
                    </h2>
                </Row>
            </Container>
            <Container
                fluid
            >
                <HashScroll
                id="about"
                />
                <Row className="justify-content-center text-dark">
                    <Col xs={12} sm={12} md={8} className="max-readable-width">
                        <h2>Less is more.</h2>
                        <p>At LUSTRUS, we are inspired by minimalism. Our bars deliver complete skincare, designed to cleanse, nourish, and rejuvenate your body and mind.</p>
                        <h2>Touch of luxury. Vegan.</h2>
                        <p>LUSTRUS is a new way to think about skincare and the environment. Our products are vegan, handcrafted in small batches using only the best ingredients. The result - luxurious skincare that is good for you and for the planet.</p>
                        <p>Our products are:</p>
                        <ul>
                            <li>Natural, high quality ingredients</li>
                            <li>Plastic free</li>
                            <li>Minimal packaging</li>
                            <li>Palm oil free</li>
                            <li>Paraben free</li>
                            <li>Sulphates free</li>
                            <li>Never tested on animals</li>
                            <li>Plant-based</li>
                            <li>Vegan</li>
                        </ul>

                    </Col>
                </Row>
            </Container>
            <Newsletter />
            <HashScroll
                id="shop"
            />
            <Container fluid>
                <Row className="justify-content-center text-dark">
                    <Col xs={12} sm={12} md={8} className="max-readable-width">
                        <h2>Explore our range.</h2>
                    </Col>
                </Row>
                <AllProducts />
            </Container>
            <HashScroll
                id="contact"
            />
            <Container fluid>
                <Row className="justify-content-center bg-light text-dark">
                    <Col xs={12} sm={12} md={8} className="max-readable-width contact-us">
                        <h3>Get in touch.</h3>
                        <p>We'd love to hear from you - share your thoughts and experiences to help us create products tailored to you.</p>

                        <p>Find us <a href="https://www.instagram.com/lustrusofficial/" title="LUSTRUS Official Instragram">@lustrusofficial</a></p>

                        <p>#lustrus #skincare #skincareroutine #glowingskin #naturalskincare</p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Home
