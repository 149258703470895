import { createAsyncThunk, createSlice } from '@reduxjs/toolkit/';
import { functions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';

const createPaymentIntentFunction = httpsCallable(functions, 'createPaymentIntent');
const updatePaymentIntentFunction = httpsCallable(functions, 'updatePaymentIntent');

export const createPaymentIntent = createAsyncThunk(
    'stripe/createPaymentIntent',
    async (arg, { rejectWithValue, getState }) => {
        const { basket, customer_id, fname, lname } = arg;
        try {
            const result = await createPaymentIntentFunction({
                basket: basket,
                customer_id: customer_id,
                fname: fname,
                lname: lname,
            });
            const { id, client_secret } = result.data;
            return ({
                paymentIntentId: id,
                clientSecret: client_secret
            });
        } catch (e) {
            const { code, name, message, stack } = e
            return rejectWithValue({
                code: code,
                name: name,
                message: message,
                stack: stack
            });
        }
    }
)

export const updatePaymentIntent = createAsyncThunk(
    'stripe/updatePaymentIntent',
    async (arg, { rejectWithValue }) => {
        const { basket, id, fname, lname } = arg;
        try {
            const result = await updatePaymentIntentFunction({
                basket: basket || null,
                id: id,
                fname: fname || null,
                lname: lname || null
            });
            return ({
                paymentIntentId: result.data.id,
                clientSecret: result.data.client_secret
            });
        } catch (e) {
            const { code, name, message, stack } = e
            return rejectWithValue({
                code: code,
                name: name,
                message: message,
                stack: stack
            });
        }
    }
)

export const updateShippingAddress = createAsyncThunk(
    'stripe/updateShippingAddress',
    (arg, { rejectWithValue }) => {
        try {
            const { fname, lname, addressLine1, addressLevel2, postalCode } = arg;
            if (!!fname && !!lname && !!addressLine1 && !!addressLevel2 && !!postalCode) {
                return ({
                    ...arg,
                    isValid: true
                })
            }
            else {
                return ({
                    ...arg,
                    isValid: false
                })
            }
        }
        catch (e) {
            const { code, name, message, stack } = e
            return rejectWithValue({
                code: code,
                name: name,
                message: message,
                stack: stack
            });
        }
    }
)

export const confirmShippingAddress = createAsyncThunk(
    'stripe/confirmShippingAddress',
    (arg, { rejectWithValue, getState, dispatch }) => {
        const { fname, lname, isValid } = arg;
        const state = getState()
        const { paymentIntentId } = state.stripe
        try {
            if (!!isValid) {
                dispatch(updatePaymentIntent({
                    id: paymentIntentId,
                    fname: fname,
                    lname: lname
                }))
                return ({
                    ...arg,
                    isConfirmed: true
                })
            }
            else {
                return ({
                    ...arg,
                    isConfirmed: false
                })
            }
        }
        catch (e) {
            const { code, name, message, stack } = e
            return rejectWithValue({
                code: code,
                name: name,
                message: message,
                stack: stack
            });
        }
    }
)

export const stripeSlice = createSlice({
    name: "stripe",
    initialState: {
        stripePromise: null,
        paymentIntentId: null,
        clientSecret: null,
        shippingAddress: {
            fname: "",
            lname: "",
            addressLine1: "",
            addressLevel1: "",
            addressLevel2: "",
            postalCode: "",
            isValid: false,
            isConfirmed: false,
        },
        loading: false,
        error: null
    },
    reducers: {
        editShippingAddress: (state) => {
            state.shippingAddress.isConfirmed = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createPaymentIntent.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createPaymentIntent.fulfilled, (state, action) => {
                state.loading = false;
                state.paymentIntentId = action.payload.paymentIntentId;
                state.clientSecret = action.payload.clientSecret;
            })
            .addCase(createPaymentIntent.rejected, (state, action) => {
                state.loading = false;
                const { code, name, message, stack } = action.payload
                state.error = {
                    code: code,
                    name: name,
                    message: message,
                    stack: stack
                };
            })
            .addCase(updatePaymentIntent.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updatePaymentIntent.fulfilled, (state, action) => {
                state.loading = false;
                state.paymentIntentId = action.payload.paymentIntentId;
                state.clientSecret = action.payload.clientSecret;
            })
            .addCase(updatePaymentIntent.rejected, (state, action) => {
                state.loading = false;
                const { code, name, message, stack } = action.payload
                state.error = {
                    code: code,
                    name: name,
                    message: message,
                    stack: stack
                };
            })
            .addCase(updateShippingAddress.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateShippingAddress.fulfilled, (state, action) => {
                state.loading = false;
                state.shippingAddress = action.payload
            })
            .addCase(updateShippingAddress.rejected, (state, action) => {
                state.loading = false;
                const { code, name, message, stack } = action.payload
                state.error = {
                    code: code,
                    name: name,
                    message: message,
                    stack: stack
                };
            })
            .addCase(confirmShippingAddress.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(confirmShippingAddress.fulfilled, (state, action) => {
                state.loading = false;
                state.shippingAddress = action.payload
            })
            .addCase(confirmShippingAddress.rejected, (state, action) => {
                state.loading = false;
                const { code, name, message, stack } = action.payload
                state.error = {
                    code: code,
                    name: name,
                    message: message,
                    stack: stack
                };
            })
    }
})

export const { editShippingAddress } = stripeSlice.actions;

export default stripeSlice.reducer;