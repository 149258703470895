import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import NavBar from "./components/NavBar";
import Checkout from "./routes/Checkout";
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from "./features/products/productsSlice";
import { useEffect } from "react";
import Home from "./routes/Home";
import OurStory from "./routes/OurStory";
import SignUp from "./routes/SignUp";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import SignIn from './routes/SignIn';
import Footer from "./components/Footer";
import CookiePopup from "./components/CookiePopup";
import { getCookies } from './features/cookies/cookiesSlice';
import Basket from "./components/Basket";
import { ToastContainer, Zoom } from "react-toastify";
import { updateUser } from './features/user/userSlice';
import { authStateObserver } from "./utils/auth";
import ProtectedRoute from "./utils/ProtectedRoute";
import OrderConfirmation from "./routes/OrderConfirmation";

const routes = [
  {
    name: "Home",
    component: Home,
    path: "/",
    isExact: true,
    protected: false,
    hideNav: false,
  },
  {
    name: "Our Story",
    component: OurStory,
    path: "/our-story",
    isExact: true,
    protected: false,
    hideNav: false,
  },
  {
    name: "Checkout",
    component: Checkout,
    path: "/checkout",
    isExact: true,
    protected: true,
    hideNav: true,
  },
  {
    name: "Privacy Policy",
    component: PrivacyPolicy,
    path: "/privacy-policy",
    isExact: true,
    protected: false,
    hideNav: false,
  },
  {
    name: "Sign Up",
    component: SignUp,
    path: "/sign-up",
    isExact: true,
    protected: false,
    hideNav: false,
  },
  {
    name: "Sign In",
    component: SignIn,
    path: "/sign-in",
    isExact: true,
    protected: false,
    hideNav: false,
  },
  {
    name: "Order Confirmation",
    component: OrderConfirmation,
    path: "/order-confirmation/success/",
    isExact: true,
    protected: true,
    hideNav: true,
  },
]

function App() {

  const dispatch = useDispatch();

  const cookies = useSelector(state => state.cookies)
  useEffect(() => {
    dispatch(getCookies())
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProducts())
  }, [dispatch])

  useEffect(() => {
    const unsubscribe = authStateObserver(dispatch, updateUser)
    return () => {
      unsubscribe()
    }
  }, [dispatch])



  return (
    <>
      <Router>
        <NavBar
          routes={routes}
        />
        <Basket />
        <div
          className="site-content"
        >
          <Switch>
            {routes.map((route, key) => {
              if (route.protected) {
                return (
                  <ProtectedRoute
                    key={key}
                    path={route.path}
                    exact={route.isExact}
                    comp={route.component}
                  />
                )
              }

              return (
                <Route
                  key={key}
                  path={route.path}
                  exact={route.isExact}
                  component={route.component}
                />
              )
            })}
            <Redirect from="*" to="/" />
          </Switch>
        </div>
        <Footer />
        {
          !cookies.hasOwnProperty("cookiesPolicy") &&
          <CookiePopup />
        }
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          transition={Zoom}
        />
      </Router>
    </>
  );
}

export default App;