import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import { newsletterFunction } from "../../firebase";
import HashScroll from '../../components/HashScroll';
import "./styles.scss";

const Newsletter = () => {

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [userDetails, setUserDetails] = useState({
        email: "",
        fname: "",
        lname: ""
    });
    const [pending, setPending] = useState(false);
    const [message, setMessage] = useState("");

    const subscribeToNewsletter = (event) => {
        event.preventDefault();
        setPending(true);
        newsletterFunction(userDetails)
            .then(response => {
                if (response.data.status === 201) {
                    setMessage("Thank you, you're now on our mailing list.");
                }
                else if (response.data.status === 400) {
                    setMessage("Looks like you're already signed up!");
                }
                setPending(false);
            })
            .catch(error => {
                setPending(false);
                setMessage("Something went wrong, please try again later.");
            });
    }

    useEffect(() => {
        const { email, fname, lname } = userDetails;
        if (!!email && !!fname && !!lname) {
            setButtonDisabled(false)
        }
        else {
            setButtonDisabled(true)
        }
    }, [userDetails])

    return (
        <Container
            className="newsletter-container bg-primary text-light"
            fluid
        >
            <HashScroll
                id="newsletter"
            />
            <Row className="justify-content-center">
                <Col
                    xs={12}
                    sm={12}
                    md={8}
                    className="max-readable-width"
                >
                    <h2>Join the revolution!</h2>
                    <p>Sign up to receive new product announcements, exclusive offers, and more.</p>
                    <Form
                        onSubmit={subscribeToNewsletter}
                    >
                        <Form.Group
                            className="mb-3 form-field-name"
                            controlId="fname"
                            value={userDetails.fname}
                            onChange={(e) => setUserDetails({ ...userDetails, fname: e.target.value })}
                        >
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" name="fname" />
                        </Form.Group>
                        <Form.Group
                            className="mb-3 form-field-name"
                            controlId="lname"
                            value={userDetails.lname}
                            onChange={(e) => setUserDetails({ ...userDetails, lname: e.target.value })}
                        >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" name="lname" />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="email"
                            value={userDetails.email}
                            onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
                        >
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" />
                        </Form.Group>
                        <Button
                            variant="outline-light"
                            disabled={buttonDisabled || pending}
                            type="submit"
                        >
                            Subscribe
                        </Button>
                    </Form>
                    <p
                        className="newsletter-message"
                    >{message}</p>
                </Col>
            </Row>
        </Container>
    )
}

export default Newsletter;