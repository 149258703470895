import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentDocs } from "../../features/payments/paymentsSlice";

const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const OrderConfirmation = () => {

    const query = useQuery();
    const paymentIntentQuery = query.get("payment_intent")
    const dispatch = useDispatch();
    const payments = useSelector((state) => state.payments.payments);
    const paymentDoc = payments[payments.findIndex((element) => element.paymentIntent.id === paymentIntentQuery)]
    const [loading, setLoading] = useState(true);
    const [retry, setRetry] = useState(2);
    const [error, setError] = useState(false);

    const convertDate = (epoch) => {
        if (epoch) {
            return new Date(epoch * 1000)
        }
    }

    var paymentDate
    if (paymentDoc) {
        paymentDate = convertDate(paymentDoc.paymentIntent.created)
    }

    useEffect(() => {
        if (payments.findIndex((element) => element.paymentIntent.id === paymentIntentQuery) === -1 && retry > 0) {
            dispatch(getPaymentDocs())
            setTimeout(() => {
                setRetry(retry - 1)
            }, 3000)
        }
        if (payments.findIndex((element) => element.paymentIntent.id === paymentIntentQuery) !== -1) {
            setLoading(false)
        }
        else if (retry === 0) {
            setLoading(false)
            setError(true)
        }
    }, [dispatch, payments, retry])

    if (loading) {
        return (
            <Container
                className="text-dark mt-4"
            >
                <Row>
                    <Col>
                        <h2>Order confirmed</h2>
                        <p>We're just loading the details...</p>
                    </Col>
                </Row>
            </Container>
        )
    }

    if (error) {
        return (
            <Container
                className="text-dark mt-4"
            >
                <Row>
                    <Col>
                        <h2>We're having some trouble</h2>
                        <p>There was an error retrieving the order details. Please check your email for an order confirmation</p>
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <>
            <Container
                className="text-dark"
            >
                <Row
                    className="mt-4"
                >
                    <Col
                    >
                        <h1>Order #{paymentDoc.orderNumber ? paymentDoc.orderNumber : 'unknown'}</h1>
                    </Col>
                </Row>
                {paymentDate &&
                    <Row>
                        <Col>
                            <p>Ordered on {paymentDate.getDate()}-{paymentDate.getMonth()}-{paymentDate.getFullYear()}</p>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col
                        className="mt-4"
                        xs={12}
                        md={6}
                    >
                        <h2>Items ordered</h2>
                        {
                            !loading &&
                            paymentDoc.items.map((item, index) => {
                                return (
                                    <Container
                                        key={index}
                                        className="basket-item-container"
                                        fluid
                                    >
                                        <Row>
                                            <Col
                                                xs={6}
                                                sm={4}
                                                className="checkout-image-container p-0"
                                            >
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/img/${item.title.toLowerCase()}-product-photo.jpg`}
                                                    className="checkout-image"
                                                    alt={item.title + " soap bar"}
                                                />
                                            </Col>
                                            <Col
                                                xs={6}
                                                sm={8}
                                                className="d-flex flex-column justify-content-center"
                                            >
                                                <Row>
                                                    <Col
                                                    >
                                                        <h5
                                                            className="my-2"
                                                        >{item.title}</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        xs={8}
                                                        sm={4}
                                                    >
                                                        <p
                                                            className="my-2"
                                                        >QTY: {item.quantity}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                )
                            })
                        }
                        <Row>
                            <Col
                                xs={4}
                                sm={3}
                            >
                                <p
                                    className="my-1"
                                >Subtotal</p>
                            </Col>
                            <Col
                            >
                                <p
                                    className="my-1"
                                >£{paymentDoc.subtotal.toFixed(2)}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs={4}
                                sm={3}
                            >
                                <p
                                    className="my-1"
                                >Delivery</p>
                            </Col>
                            <Col>
                                <p
                                    className="my-1"
                                >£{paymentDoc.deliveryCost.toFixed(2)}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs={4}
                                sm={3}
                            >
                                <h5
                                    className="my-1"
                                >Total</h5>
                            </Col>
                            <Col>
                                <h5
                                    className="my-1"
                                >£{paymentDoc.total.toFixed(2)}</h5>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        xs={12}
                        md={6}
                    >
                        <Row>
                            <Col
                                className="mt-4"
                            >
                                <h2>Shipping Address</h2>
                                {paymentDoc.shipping &&
                                    paymentDoc.shipping.address.line1 &&
                                    <p
                                        className="mt-3 mb-1"
                                    >{paymentDoc.shipping.address.line1}</p>
                                }
                                {paymentDoc.shipping &&
                                    paymentDoc.shipping.address.line2 &&
                                    <p
                                        className="my-1"
                                    >{paymentDoc.shipping.address.line2}</p>
                                }
                                {paymentDoc.shipping &&
                                    paymentDoc.shipping.address.city &&
                                    <p
                                        className="my-1"
                                    >{paymentDoc.shipping.address.city}</p>
                                }
                                {paymentDoc.shipping &&
                                    paymentDoc.shipping.address.postal_code &&
                                    <p
                                        className="my-1"
                                    >{paymentDoc.shipping.address.postal_code}</p>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                className="mt-4"
                            >
                                <h2>Billing Details</h2>
                                {paymentDoc.paymentIntent.charges.data ?
                                    <p>{paymentDoc.paymentIntent.charges.data[0].payment_method_details.card.brand + " card •••• " + paymentDoc.paymentIntent.charges.data[0].payment_method_details.card.last4}</p>
                                    :
                                    'Details unavailable'
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default OrderConfirmation
