
import { useState } from "react";
import "./styles.scss";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch } from 'react-redux';
import { addToBasket } from "../../features/basket/basketSlice";
import { queueToast } from '../../features/toast/toastSlice';

const ProductCard = ({ id, title, subTitle, text, price, inStock, imageURL, description, learnIngredients, ingredientsINCI }) => {

    const color = "eadeda";

    const dispatch = useDispatch();

    const dispatchAddToBasket = (id, title, quantity) => {
        dispatch(addToBasket({
            id: id,
            title: title,
            quantity: quantity
        }))
        dispatch(queueToast({
            message: `${title} add to basket`,
            type: "success"
        }))
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Card className="border-light">
                <Card.Img variant="top" src={imageURL || `https://via.placeholder.com/600x400/${color}/${color}`} />
                <Card.Body>
                    <Card.Title
                        className="product-card-title"
                    >
                        <div>{title}</div>
                        <div>£{price}</div>
                    </Card.Title>
                    <Card.Subtitle
                        className="product-card-subtitle"
                    >{subTitle}</Card.Subtitle>
                    <Card.Text>
                        {text}
                    </Card.Text>
                    <div className="add-to-basket-container">
                        {inStock ?
                            <Button
                                onClick={() => dispatchAddToBasket(id, title, 1)}
                                variant="secondary"
                            >
                                Add to Bag
                            </Button>
                            :
                            <Button
                                disabled
                                variant="secondary"
                            >
                                Coming soon
                            </Button>
                        }
                        <Button
                            onClick={handleShow}
                            variant="outline-secondary ms-2"
                        >
                            View Details
                        </Button>
                    </div>
                </Card.Body>
            </Card>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title
                        className="modal-title"
                    >
                        {title}
                    </Modal.Title>
                    <h4
                        className="modal-price mb-0 me-4"
                    >£{price}</h4>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </Modal.Body>
                <Accordion flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <h6
                                className="mb-1"
                            >Ingredients</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div
                                className="ingredients-list-inci"
                                dangerouslySetInnerHTML={{ __html: ingredientsINCI }} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Modal.Footer>
                    {inStock ?
                        <Button
                            onClick={() => dispatchAddToBasket(id, title, 1)}
                            variant="secondary"
                        >
                            Add to Bag
                        </Button>
                        :
                        <Button
                            disabled
                            variant="secondary"
                        >
                            Coming soon
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ProductCard;