import { createAsyncThunk, createSlice } from '@reduxjs/toolkit/';
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from '../../firebase';


export const getProducts = createAsyncThunk(
    'firebase/getProducts',
    async () => {
        const q = await getDocs(query(collection(db, "products"),where("show", "==", true)));
        const products = [];
        q.forEach((doc) => {
            products.push(doc.data());
        });
        return products;
    }
);

const productsSlice = createSlice({
    name: 'products',
    initialState: { products: [], status: 'idle', error: null },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.pending, (state, action) => {
                state.status = 'loading'
                state.error = null
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                action.payload.forEach((data) => {
                    if (state.products.findIndex(s => s.id === data.id) === -1) {
                        state.products.push(data)
                    }
                })
                state.status = 'idle'
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.error = action.error
                state.status = 'idle'
            })
    }
});

export default productsSlice.reducer;