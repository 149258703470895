import "./styles.scss";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const OurStory = () => {
    return (
        <Container
        fluid
        className="our-story d-flex justify-content-center align-items-center"
        >
            <Row
            className="justify-content-center"    
            >
                <Col
                    xs={12}
                    className="max-readable-width our-story-content"
                >
                    <h1 className="text-dark">Our Story</h1>
                    <p className="text-dark">LUSTRUS is an independent luxury skincare brand run by our energetic little family based in London.</p>
                    <p className="text-dark">Our Story began with a realisation - our bathroom shelves were cluttered with many skincare products, inevitably in plastic packaging. Multiple products make skincare more time-consuming and complex, whilst plastic waste generated from each product is harmful both to us and the planet. We thought, there must be a better way!</p>
                    <p className="text-dark">To reduce our waste, Hanna started producing soap, which soon replaced a number of specific products we used to purchase - liquid hand soap, face wash, shower gel, moisturiser and exfoliating scrubs - and we never looked back.</p>
                    <p className="text-dark">Those who tried our LUSTRUS bars, encouraged us to make them available to others. We are excited to be able to share our creations with you!</p>
                </Col>
            </Row>
        </Container>
    )
}

export default OurStory
