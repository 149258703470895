import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import ProductCard from "../ProductCard";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const AllProducts = () => {

    const products = useSelector(state => state.products.products);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        if (products !== []) {
            setPending(false)
        }
    }, [products])

    if (pending !== true) {
        return (
            <Row className="justify-content-center">
                {products.map((product, key) => {

                    const { id, title, subTitle, price, inStock, description, learnIngredients, ingredientsINCI } = product

                    return (
                        <Col key={key} xs={12} sm={10} md={6} lg={5} xl={4}>
                            <ProductCard
                                id={id}
                                title={title}
                                subTitle={subTitle}
                                price={price}
                                inStock={inStock}
                                imageURL={`${process.env.PUBLIC_URL}/img/${title.toLowerCase()}-product-photo.jpg`}
                                description={description}
                                learnIngredients={learnIngredients}
                                ingredientsINCI={ingredientsINCI}
                            />
                        </Col>
                    )
                })}
            </Row>
        )
    }
    return (
        <div>Loading...</div>
    )
}

export default AllProducts
