import { createSlice } from '@reduxjs/toolkit/';
import Cookies from 'js-cookie';

const cookiesSlice = createSlice({
    name: "cookies",
    initialState: {},
    reducers: {
        getCookies: (state, action) => {
            const cookies = Cookies.get();
            if (!cookies.undefined) {
                for (const p in cookies) {
                    state[p] = cookies[p]
                }
            }
        },
        setCookies: (state, action) => {
            const { name, value, options } = action.payload;
            Cookies.set(name, value, options)
        }
    }
})

export const { getCookies, setCookies } = cookiesSlice.actions;

export default cookiesSlice.reducer;