import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const PrivacyPolicy = () => {
    return (
        <Container fluid>
            <Row
            className="justify-content-center"
            >
                <Col
                    xs={12}
                    className="max-readable-width"
                >
                    <h1>Privacy Policy</h1>
                    <p>Last updated: November 4, 2021</p>
                    <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                    <h2>Interpretation and Definitions</h2>
                    <h3>Interpretation</h3>
                    <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                    <h3>Definitions</h3>
                    <p>For the purposes of this Privacy Policy:</p>
                    <ul>
                        <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
                        <li><strong>Company</strong> (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to LUSTRUS LTD, a company registered in England and Wales with company number 12497274.</li>
                        <li><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
                        <li><strong>Country</strong> refers to: United Kingdom</li>
                        <li><strong>Device</strong> means any device that can access the Service such as a computer, a smartphone or a digital tablet.</li>
                        <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
                        <li><strong>Service</strong> refers to the Website.</li>
                        <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analysing how the Service is used.</li>
                        <li><strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</li>
                        <li><strong>Website</strong> refers to the LUSTRUS website, accessible from <a href="lustrus.co" rel="noreferrer noopener">lustrus.co</a></li>
                        <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
                    </ul>
                    <h2>Collecting and Using Your Personal Data</h2>
                    <h3>Types of Data Collected</h3>
                    <h4>Personal Data</h4>
                    <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                    <ul>
                        <li>Email address</li>
                        <li>First name and last name</li>
                        <li>Phone number</li>
                        <li>IP Address</li>
                    </ul>
                    <h4>Tracking Technologies and Cookies</h4>
                    <ul>
                        <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
                        <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
                    </ul>
                    <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
                    <p>Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies: <a href="https://www.freeprivacypolicy.com/blog/cookies/" rel="noreferrer noopener">Cookies: What Do They Do?</a>.</p>
                    <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
                    <ul>
                        <li><strong>Necessary / Essential Cookies</strong> Type: Session Cookies Administered by: Us Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</li>
                        <li><strong>Cookies Policy / Notice Acceptance Cookies</strong> Type: Persistent Cookies Administered by: Us Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</li>
                        <li><strong>Functionality Cookies</strong> Type: Persistent Cookies Administered by: Us Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</li>
                    </ul>
                    <p>For more information about the cookies we use and your choices regarding cookies, please visit the Cookies section of our Privacy Policy.</p>
                    <h3>Use of Your Personal Data</h3>
                    <p>The Company may use Personal Data for the following purposes:</p>
                    <ul>
                        <li><strong>Fulfilling an order </strong></li>
                        <li><strong>Contacting you</strong> about product updates, offers, and other LUSTRUS news </li>
                        <li><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</li>
                        <li><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</li>
                    </ul>
                    <p>We may share Your personal information in the following situations:</p>
                    <ul>
                        <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</li>
                        <li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
                        <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li></ul>
                    <h3>Retention of Your Personal Data</h3>
                    <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                    <h3>Transfer of Your Personal Data</h3>
                    <p>Your information, including Personal Data, is processed at the Company’s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                    <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                    <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
                    <h3>Disclosure of Your Personal Data</h3>
                    <h4>Business Transactions</h4>
                    <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                    <h4>Law enforcement</h4>
                    <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                    <h4>Other legal requirements</h4>
                    <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                    <ul>
                        <li>Comply with a legal obligation</li>
                        <li>Protect and defend the rights or property of the Company</li>
                        <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                        <li>Protect the personal safety of Users of the Service or the public</li>
                        <li>Protect against legal liability</li>
                    </ul>
                    <h3>Security of Your Personal Data</h3>
                    <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
                    <h2>Detailed Information on the Processing of Your Personal Data</h2>
                    <p>The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store, use, process and transfer information about Your activity on Our Service in accordance with their Privacy Policies.</p>
                    <h3>Usage, Performance and Miscellaneous</h3>
                    <p>We may use third-party Service Providers to provide better improvement of our Service.</p>
                    <p>We use Google Firebase to process and store your personal information.</p>
                    <p>Below we’ve summarised how the Firebase services collect and process your data:</p>
                    <ul>
                        <li>Cloud Functions for Firebase: Collects a user's IP address for event-handling and HTTP functions</li>
                        <li>Firebase Crash Reporting: Collects crash traces and instances IDs for diagnostic purposes</li>
                        <li>Firebase Crashlytics: The latest version collects a user's installation UUID and IP address, older versions collect other types of data (more information here)</li>
                        <li>Firebase Hosting: Collects IP addresses for security and diagnostic purposes</li>
                        <li>Firebase Performance Monitoring: Collects a user's instance ID and IP address to monitor resource access and map performance events</li>
                        <li>Google Analytics for Firebase: Can collect a user's mobile ad ID, IDfV ID (iOS) or Android ID, instance ID, and Analytics app instance ID for analytics purposes</li>
                    </ul>
                    <p>For more information please visit <a href="https://policies.google.com/privacy">‘How Google uses data when you use our partners’ sites or apps’</a></p>
                    <p>We use sendinblue to handle email newsletters, governed by their <a href="https://www.sendinblue.com/legal/privacypolicy/">Privacy Policy</a></p>
                    <p>Payments are handled by Stripe and are governed by their <a href="https://stripe.com/en-gb/privacy-center/legal">Privacy Policy</a></p>
                    <ul>
                        <li><strong>Google reCAPTCHA</strong> is a SPAM protection service provided by Google that helps protect websites from spam and abuse. It may analyse traffic, (potentially containing Users’ Personal Data) with the purpose of filtering the parts of traffic recognized as Bots or SPAM. The Google&nbsp;<a href="https://policies.google.com/privacy">Privacy Policy</a>&nbsp;and&nbsp;<a href="https://policies.google.com/terms">Terms of Service</a>&nbsp;apply.</li>
                    </ul>
                    <h2 id="about" className="hash-offset">Cookies</h2>
                    <p>We use a Cookie to check whether you have seen our message about third-party cookies on the website.</p>
                    <p>Our site uses third-party Cookies from Google reCAPTCHA for the purposes of SPAM protection, and as such the Google&nbsp;<a href="https://policies.google.com/privacy">Privacy Policy</a>&nbsp;and&nbsp;<a href="https://policies.google.com/terms">Terms of Service</a>&nbsp;apply.</p>
                    <h2>Links to Other Websites</h2>
                    <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party’s site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
                    <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                    <h2>Changes to this Privacy Policy</h2>
                    <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                    <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the “Last updated” date at the top of this Privacy Policy.</p>
                    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                    <h2>Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                    <ul><li>By email: <a href="mailto:hello@lustrus.co">hello@lustrus.co</a></li></ul>
                </Col>
            </Row>
        </Container>
    )
}

export default PrivacyPolicy
