import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import basketReducer from '../features/basket/basketSlice';
import productReducer from '../features/products/productsSlice';
import cookiesReducer from '../features/cookies/cookiesSlice';
import toastReducer from '../features/toast/toastSlice';
import userReducer from '../features/user/userSlice';
import stripeReducer from '../features/stripe/stripeSlice';
import paymentsReducer from '../features/payments/paymentsSlice';


const reducers = combineReducers({
    basket: basketReducer,
    products: productReducer,
    cookies: cookiesReducer,
    toast: toastReducer,
    user: userReducer,
    stripe: stripeReducer,
    payments: paymentsReducer,
})

const store = configureStore({
    reducer: reducers,
    devTools: process.env.REACT_APP_REDUX_DEVTOOLS === "true",
})

export default store;